<template>
  <header class="header">
    <div class="logo" @click="toHomePage">
      传统知识守证创新鉴证学习平台
    </div>
    <div class="header-title">
      专家评审
    </div>
    <div v-if="userInfo.userId" class="user-info-wrapper hover-effect">
      <el-tag v-if="userInfo.isLeader === 1" class="tag-leader">评审组长</el-tag>
      <el-dropdown class="dropdown-home" trigger="click">
        <div class="user-info">
          <!--<el-avatar size="medium"></el-avatar>-->
          <span>{{userInfo.userId}}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item divided @click.native="pwdDialogShow = true">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="handleUserLogout">
            <span style="display:block;">退出登陆</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="修改密码" :visible.sync="pwdDialogShow">
      <el-form ref="pwdForm" :model="pwdForm" :rules="rules" status-icon
               label-width="100px" style="max-width: 600px;">
        <el-form-item label="原密码" prop="pass">
          <el-input type="password" v-model="pwdForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="passNew">
          <el-input type="password" v-model="pwdForm.passNew" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passNewConfirm">
          <el-input type="password" v-model="pwdForm.passNewConfirm" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label-width="0" align="center">
          <el-button type="primary" @click="submitForm">提交</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </header>
</template>

<script>
  import {mapState} from 'vuex'
  import {userLogout} from "@/page-home/api/auth";
  import {removeAllToken} from "@/page-home/utils/storage";
  import {changeExpertPwd} from "@/page-home/api/expert";
  export default {
    name: "YHeaderExpert",
    data() {
      const validateConfirmPass = (rule, value, callback) => {
        if (value !== this.pwdForm.passNew) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        pwdDialogShow: false,
        pwdForm: {},
        rules: {
          pass: [
            {required: true, message: '旧密码必填', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'}
          ],
          passNew: [
            {required: true, message: '请输入新密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'}
          ],
          passNewConfirm: [
            {required: true, message: '请再次输入新密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'},
            {validator: validateConfirmPass, trigger: 'blur'}
          ]
        }
      }
    },

    computed: {
      ...mapState({
        userInfo: state => state.userInfo
      })
    },

    methods: {
      handleLoginClick() {
        // this.loginDialogVisible = true
        this.$router.push('/login')
      },

      toHomePage() {
        // this.$router.replace('/')
      },

      handleUserLogout() {
        userLogout()
        this.$store.commit('SET_USER_INFO', {})
        removeAllToken()
        this.$router.replace('/expert/login')
      },

      submitForm() {
        this.$refs['pwdForm'].validate(valid => {
          if (valid) {
            changeExpertPwd(this.pwdForm).then(res => {
              this.$message.success('密码修改成功，请重新登录')
              removeAllToken()
              setTimeout(() => {
                this.$router.push(`/expert/login`)
              }, 300)
            })
          } else {
            return
          }
        })
      },

      resetForm() {
        this.$refs['pwdForm'].resetFields()
      }
    },

    created() {
      console.log('header create');
      // if (!this.userInfo.uid) {
      //   getUserDetail().then(res => {
      //     this.$store.commit('SET_USER_INFO', res.data)
      //   })
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #f1f1f1;
    background-color: #ffffff;
    .logo {
      font-size: 20px;
      font-weight: 500;
      padding: 20px 0;
      cursor: pointer;
    }
    .header-title {
      margin-left: 32px;
      font-size: 18px;
      color: #004DF8;
    }
  }
  .user-info-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    padding: 0 8px;
    font-size: 18px;
    color: #5a5e66;
    &.hover-effect {
      cursor: pointer;
      transition: background .3s;
      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
  }
  .tag-leader {
    margin-right: 30px;
  }
</style>

<style lang="scss">
  .el-dropdown-menu {
    .el-dropdown-menu__item--divided {
      margin-top: 0;
    }
    .el-dropdown-menu__item {
      line-height: 26px;
    }
  }
</style>
